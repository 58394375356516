.panels-flexible-two_column_content_inverse {
  overflow: hidden;
}
.panels-flexible-two_column_content_inverse-inside   {
  @extend .row;

  .panels-flexible-region-two_column_content_inverse-center {
    @extend .col-md-8;
    @extend .col-sm-6;
    @extend .col-xs-12;
  }
  .panels-flexible-region-two_column_content_inverse-sidebar {
    @extend .col-md-4;
    @extend .col-sm-6;
    @extend .col-xs-12;
  }
}
