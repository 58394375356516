.paragraphs-item-three-column-content {
  overflow: hidden;
  @extend .row;
  margin-left: -12px;
  margin-right: -13px;
  .field-highlights {
    @extend .col-md-4;
    @extend .col-sm-6;

    &.center {
      text-align: center;
    }
  }
}
