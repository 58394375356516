.footer-wrapper {
  background: color('blue');
  color: white;
  overflow: auto;
  @include typeface(special);
  margin-top: 25px;
  @include type-layout(s, 1);

  a {
    color: white !important;

    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      color: white !important;
    }
  }
}
