.panels-flexible-two_column_content {
  overflow: hidden;
}
.panels-flexible-two_column_content-inside {
  @extend .row;

  .panels-flexible-region-two_column_content-center {
    @extend .col-md-8;
    @extend .col-sm-6;
    @extend .col-xs-12;
  }
  .panels-flexible-region-two_column_content-sidebar {
    @extend .col-md-4;
    @extend .col-sm-6;
    @extend .col-xs-12;
    @media (min-width: 768px) {
      padding-top: 1.5em;
    }
  }
}
