// background
html.full-height {
  height: 100%;
  min-height: 100%;
  position: relative;

  .node-type-landing-page,
  .bg {
    // height: 100%;
    height: calc(100vh - 120px);
    min-height: 100%;
    position: relative;
  }

}

.not-front.node-type-landing-page {
  .panels-flexible-row-first.Top.Content {
    padding: 10px;
    background: white;
    background: rgba( white, 0.5 );
  }
}

.node-type-landing-page {
  height: 100%;
  min-height: 100%;
  position: relative;

  // hide logo and title
  .header__logo,
  h1 {
    @include visually-hidden;
  }

  // set max width to logo
  .header__logo {
    max-width: 300px;
  }

  // center content and set fixed with
  .layout-3col__full {
    text-align: center;

    .panels-flexible-landing_page {
      max-width: 768px;
      margin: 0 auto;
    }

    img {
      display: block;
      width: auto;
      margin: 0 auto;
    }
  }

  // image
  .pane-node-field-image {
    width: 150px;
    text-align: center;
    margin: 0 auto;
    
    @media all and (min-width: 768px) and (min-height: 710px) {
      width: auto;
    }
    
  }

  // main contnet
  .panels-flexible-region-landing_page-center-inside {
    background-color: color('blue');
    background-color: rgba(color('blue'),.86);
    padding: 20px;
    color: color('white');
    margin-top: 25px;
    margin-bottom: 25px;
    @include typeface(special);

    h1,h2,h3,h4,h5,h6 {
      color: color('white');
    }

    a {
      color: color('white');
    }

    // body
    .field-name-body {
      font-family: $alegreya;

      h2 {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 100;
      }
    }

    // form
    input[type="email"],
    textarea {
      width: 100%;
      font-family: $alegreya;
      font-weight: 100;
    }
    label {
      color: color('text');
      font-family: $alegreya;
      font-weight: 100;
    }
    input[type="submit"] {
      width: 100%;
      border: 2px solid color('white');
      background: none;
      color: color('white');
      padding: 5px;

      &:hover,
      &:active {
        background: color('white');
        color: color('blue');
      }

      @include respond-to(m) {
        width: auto;
      }
    }

  }

  // front
  &.front {
    .bg {
      padding-bottom: 25px;
    }
    .panels-flexible-region-landing_page-center-inside {
      background: none;
    }
    // footer
    .footer-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 2;
    }
  }
}
