// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.header,
%header {
  @extend %clearfix;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  background: color('blue');

  // Wrapping link for logo.
  &__logo {
    display: block;
    float: none;
    margin: 0px auto;
    padding: 20px;
    width: 150px;
    position: relative;
    z-index: 10;

    @include respond-to(m) {
      float: left;
      margin: 20px 10px 55px 0;
      padding: 0;
      width: auto;
    }

    @include rtl() {
      float: right;
      margin: 0 0 0 10px;
    }
  }

  // Logo image.
  &__logo-image {
    vertical-align: bottom;
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    float: left;
  }

  // The name of the website.
  &__site-name {
    @extend %h1;
    margin: 0;
  }

  // The link around the name of the website.
  &__site-link {
    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;

    @include rtl() {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    // Clear the logo.
    clear: both;
  }

  // navigation
  .region-navigation {
    padding-top: 10px;
    padding-bottom: 20px;
    @include clearfix;

    .sf-menu {
      @include clearfix;
      display: inline-block;

      li.sf-depth-1 {
        border-right: 1px solid white;

        a.sf-depth-1 {
          padding: 0 15px;
          color: white;
          text-transform: uppercase;
          letter-spacing: 3px;
          text-decoration: none;
          @include type-layout(xs, .5);
          @include typeface(special);

          &:hover,
          &:active,
          &.active {
            text-decoration: underline;
          }
        }        

        // first
        &:first-of-type {
          a {
            padding-left: 0;
          }
        }

        // last
        &:last-of-type {
          border-right: 0;
          a {
            padding-right: 0;
          }
        }
      }
    }
  }

}

// offset header
body {
  padding-top: 118px;
}

// when logged in
body.admin-menu {
  .header {
    top: 29px; /* offsets the admin menu */
  }
}

// login blocks
.block-user,
.block-logintoboggan {
  text-align: left;
  padding-bottom: 10px;
  padding-top: 20px;

  span {
    color: color('white');
  }
  
  a {
    color: color('white');
    font-weight: 100;
    display: inline-block;
    padding: .25rem .5rem;
    border: 1px solid color('white');
    text-decoration: none;
    background: color('gold');

    &:hover,
    &:active,
    &:focus {
      background: color('white');
      color: color('blue');
    }
  }
}

// older browsers
.lt-ie10 {
  .block-superfish {
    span {
      display: none;
    }
  }
}